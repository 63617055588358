// @ts-nocheck

import React, { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { getRate } from "../common/api";
import { UserContext } from "../state/userContext";
import * as pdfjs from 'pdfjs-dist';
import mammoth from 'mammoth';
import { PDFDocumentProxy } from "pdfjs-dist";
import { TextContent } from "pdfjs-dist/types/web/text_layer_builder";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface FileItemRef {
    getRating: () => any;
    buttonRef: HTMLButtonElement | null;
}

const FileItem = forwardRef(({ file, positionDescription }: {
    file: File,
    positionDescription: string,
}, ref) => {
    const [rate, setRate] = React.useState({});
    const userContext = useContext(UserContext);
    const [isLoading, setLoading] = React.useState(false)
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    useImperativeHandle(ref, () => ({
        // @ts-ignore
        getRating: () => rate?.rating || 0,
        buttonRef: buttonRef.current,
    }));
    const handleSubmit = async () => {
        setLoading(true)
        try {
            const fileContent = await readFileContent(file);
            const token = await userContext.getAuthToken();
            const result = await getRate({
                cvContent: removeUnnecessaryWhiteSigns(fileContent),
                positionDescription: positionDescription,
            }, token);
            setRate(result.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setLoading(false)
        }
    };

    function readFileContent(file: File): Promise<string> {
        console.log('File name:', file.name);
        if (file.type.endsWith('pdf')) {
            console.log('PDF file');
            return readPDFFileContent(file);
        }
        console.log('DOCX file');
        return readDOCXFileContent(file);
    }

    async function readPDFFileContent(file: File): Promise<string> {
        try {
            const buffer = await file.arrayBuffer();
            return await extractTextFromPdf(buffer);
        } catch (err) {
            throw new Error(`Error reading PDF file: ${err}`);
        }
    }

    async function extractTextFromPdf(pdfContent: ArrayBuffer): Promise<string> {
        const pdf: PDFDocumentProxy = await pdfjs.getDocument(pdfContent).promise;
        const totalPageCount: number = pdf.numPages;
        const countPromises: Promise<string>[] = [];

        for (let currentPage = 1; currentPage <= totalPageCount; currentPage++) {
            const pagePromise = pdf.getPage(currentPage);
            const textPromise: Promise<string> = pagePromise.then(async (page) => {
                const textContent: TextContent = await page.getTextContent();
                return textContent.items.map((s) => s.str).join('');
            });

            countPromises.push(textPromise);
        }

        const texts: string[] = await Promise.all(countPromises);
        return texts.join('');
    }


    async function readDOCXFileContent(file: File): Promise<string> {
        const buffer = await file.arrayBuffer()
        const result = await mammoth.extractRawText({ arrayBuffer: buffer });
        return result.value;
    }

    function removeUnnecessaryWhiteSigns(text: string): string {
        return text.replace(/\s+/g, ' ').trim();
    }

    // @ts-ignore
    return (
        <tr className="table-component__row">
            <td className="table-column">{file.name}</td>
            <td className="table-column">{rate.name}</td>
            <td className="table-column">{rate.rating}</td>
            <td className="table-column">{rate.stack}</td>
            <td className="table-column">{rate.level}</td>
            <td className="table-column">{rate.opinion}</td>
            <td className="table-column">
                {isLoading ?
                    <div className="loader"></div>
                    : <button ref={buttonRef} className="default-button" disabled={!positionDescription} onClick={handleSubmit}>Rate</button>
                }
            </td>
        </tr>
    );
});

export default FileItem;
