import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    return (
        <div className="header">
            <Link to="/">
                <div className="logo-container">
                    <img src="./grape_logo.svg" alt="headerLogo"/>
                    <p className="application-name">Cv Evaluator</p>
                </div>
            </Link>
        </div>
    );
}

export default Header;
