import { useContext, useEffect } from "react";
import { UserContext } from "../state/userContext";

const LoginForm = () => {
  const userContext = useContext(UserContext);


    useEffect(() => {
        userContext.login();
    }, [userContext]);

  return (
      <></>
  );
};

export default LoginForm;
