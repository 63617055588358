// @ts-nocheck

import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const PositionDescriptionForm = () => {
    const navigate = useNavigate();
    const [positionDescription, setPositionDescription] = useState('');

    const handlePositionDescriptionChange = (event) => {
        setPositionDescription(event.target.value);
    };

    const handleNextClick = () => {
        navigate('/upload', {state: { positionDescription }});
    }

    // show form with textarea and a Next button that redirects to /upload
    // disable button if the position description is empty
    return (
        <div>
            <textarea
                placeholder="Enter the position description"
                value={positionDescription}
                onChange={handlePositionDescriptionChange}
            />
            <button className="default-button" onClick={handleNextClick} disabled={!positionDescription}>Next</button>
        </div>
    );
}

export default PositionDescriptionForm;
