import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import CvUpload from "./pages/CvUpload";
import PositionDescriptionForm from "./pages/PositionDescriptionForm";

import './App.css';
import Header from "./header/Header";
import Login from "./login/Login";
import { ContextProvider } from "./state/evaluatorContext";
import Protected from "./authentication/Protected";

const App = () => {
    return (
        <BrowserRouter>
            <ContextProvider>
                <Header/>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route
                        path="/"
                        element={
                            <Protected>
                                <PositionDescriptionForm/>
                            </Protected>
                        }
                    />
                    <Route
                        path="/upload"
                        element={
                            <Protected>
                                <CvUpload/>
                            </Protected>
                        }
                    />
                    <Route
                        path="*"
                        element=
                            {
                                <Navigate to="/"/>
                            }
                    />
                </Routes>
            </ContextProvider>
        </BrowserRouter>
    );
};

export default App;