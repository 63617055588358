import React from "react";
import UserContextProvider from "./userContext";

const EvaluatorContext = React.createContext({});

export const ContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const contextValue = {};

  return (
    <EvaluatorContext.Provider value={contextValue}>
        <UserContextProvider>{props.children}</UserContextProvider>
    </EvaluatorContext.Provider>
  );
};
