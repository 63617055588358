import { AuthenticatedTemplate } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import LoginForm from "./LoginForm";

const Login = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Navigate to="/" />
      </AuthenticatedTemplate>

      <LoginForm />
    </>
  );
};

export default Login;
