import React, { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../authentication/authConfig";
import { EventType, InteractionStatus } from "@azure/msal-browser";

type UserContextType = {
  isLogged: boolean;
  login: () => void;
  logout: () => void;
  getAuthToken: () => Promise<string>;
};

export const UserContext = React.createContext<UserContextType>({
  isLogged: false,
  login: () => {},
  logout: () => {},
  getAuthToken: async () => {
    return Promise.resolve("");
  },
});

const UserContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();


  useEffect(() => {
    instance.enableAccountStorageEvents();

    const callbackId = instance.addEventCallback((message: any) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_SUCCESS) {

        const result = message.payload;
        // Do something with the result
      } else if (message.eventType === EventType.LOGOUT_SUCCESS) {
        navigate("/login");
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, navigate]);

  const loginHandler = async () => {
    await instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const logoutHandler = () => {
    instance.logoutRedirect();
  };

  const getAuthTokenHandler = async () => {
    try {
      const authenticationResult = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return Promise.resolve(authenticationResult.accessToken);
    } catch (e) {
      logoutHandler();
      return Promise.reject("Unauthorized");
    }
  };

  const contextValue: UserContextType = {
    isLogged: isAuthenticated,
    login: loginHandler,
    logout: logoutHandler,
    getAuthToken: getAuthTokenHandler,
  };

  return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>;
};

export default UserContextProvider;
