import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:3001";

const API_CONFIG = {
  uploadUrl: API_URL + "/upload",
  summaryUrl: API_URL + "/summary",
};

export const getRate = async (body: unknown, token: string) => {
  return await axios.post(API_CONFIG.uploadUrl, body, {
    headers: {
      'Content-Type': 'application/json',
       'Authorization': `Bearer ${token}`
    },
  });
}

export const getSummary = async (body: unknown, token: string) => {
  return await axios.post(API_CONFIG.summaryUrl, body, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
}
