// @ts-nocheck

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocation, useNavigate } from "react-router-dom";
import FileItem, { FileItemRef } from "./FileItem";
import { getSummary } from "../common/api";
import { UserContext } from "../state/userContext";

const CvUpload = () => {
    const navigate = useNavigate();
    const [sortedFiles, setSortedFiles] = useState([]);
    const [summary, setSummary] = useState({});
    const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' } | null>(null);
    const userContext = useContext(UserContext);
    const location = useLocation();
    const positionDescription = location.state && location.state.positionDescription;
    const itemRefs = useRef<FileItemRef[]>([]);
    useEffect(() => {
        if (!positionDescription) {
            navigate('/');
        }
    }, [positionDescription, navigate]);

    const handleFileChange = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const uniqueFiles = Array.from(
                acceptedFiles.concat(sortedFiles).reduce((map, obj) => map.set(obj.path, obj), new Map()).values()
            );
            setSortedFiles(uniqueFiles);
        }
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: handleFileChange,
        // @ts-ignore
        accept: '.pdf, .docx',
    });

    const fetchSummary = useCallback(async () => {
        const token = await userContext.getAuthToken();
        const body = { positionDescription };
        try {
            if (positionDescription) {
                const result = await getSummary(body, token);
                setSummary(result.data);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }, [positionDescription, userContext]);

    useEffect(() => {
        fetchSummary();
    }, [fetchSummary]);

    const sortFiles = (key: string) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedArray = [...sortedFiles].sort((a, b) => {
            const aValue = itemRefs.current[sortedFiles.indexOf(a)].getRating();
            const bValue = itemRefs.current[sortedFiles.indexOf(b)].getRating();

            if (aValue < bValue) {
                return direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setSortedFiles(sortedArray);
        setSortConfig({ key, direction });
    };

    const rateAll = () => {
        itemRefs.current.forEach(ref => ref.buttonRef.click());
    };
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            padding: '20px',
        }}>

            <p className="application-name">{ // @ts-ignore
                 summary.name}
            </p>
            <p>{
                // @ts-ignore
                summary.summary
            }</p>
            <div
                {...getRootProps()}
                style={{
                    width: '500px',
                    height: '200px',
                    border: '2px dashed #aaa',
                    padding: '20px',
                    marginBottom: '20px',
                    borderColor: isDragActive ? '#007bff' : '#aaa',
                }}
            >
                <input {...getInputProps()} />
                <p>
                    {isDragActive
                        ? 'Drop the file here...'
                        : 'Drag and drop a .docx or .pdf file(s) here'}
                </p>
            </div>
            <table>
                <thead className="table-component__header">
                <tr>
                    <th className="table-column">File name</th>
                    <th className="table-column">Fullname</th>
                    <th className="table-column" onClick={() => sortFiles("rating")}>
                        <div className="flex-container">
                            <div id="sort_rate" className={sortConfig?.direction === 'asc' ? 'triangle_up' : 'triangle_down'}/>
                            <span>Rate</span>
                        </div>
                    </th>
                    <th className="table-column">Stack</th>
                    <th className="table-column">Level</th>
                    <th className="table-column">Opinion</th>
                    <th className="table-column">Actions</th>
                </tr>
                </thead>
                <tbody>
                {sortedFiles.map((file, index) => (
                    <FileItem
                        key={file.path}
                        file={file}
                        positionDescription={positionDescription}
                        ref={el => itemRefs.current[index] = el!}
                    />
                ))}
                </tbody>
                <tfoot className="table-component__footer">
                    <tr>
                        <td className="table-column"></td>
                        <td className="table-column"></td>
                        <td className="table-column"></td>
                        <td className="table-column"></td>
                        <td className="table-column"></td>
                        <td className="table-column"></td>
                        <td className="table-column">  {
                            <button className="default-button rounded" onClick={rateAll}>Rate all</button>
                        }</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default CvUpload;