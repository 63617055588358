import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../state/userContext";

// @ts-ignore
const Protected: React.FC<{ children?: React.ReactNode }> = (props) => {
  const userContext = useContext(UserContext);

  return userContext.isLogged ? props.children : <Navigate to="/login" replace />;
};

export default Protected;
